import { useState } from "react";
import { useOutsideClick } from "components/hooks/useOutsideClick";

export default function Component({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: any;
}) {
  const refSettingPanel = useOutsideClick(() => {
    setIsOpen(false);
  });

  const notifications = [
    {
      text: "New university recommendation",
      link: "/notifications/hf783sjhf73",
    },
    { text: "New course suggestion", link: "/notifications/sjhf73hf783" },
    {
      text: "New university recommendation",
      link: "/notifications/47f7sjh83h3",
    },
  ];

  return (
    <div
      ref={refSettingPanel}
      className={
        "text-sm text-gray-600 absolute w-60 max-w-sm mt-3 transform bg-gray-50 rounded-md shadow-lg -translate-x-3/4" +
        (isOpen ? " " : " hidden ")
      }
    >
      <ul className="flex flex-col p-1 my-2 space-y-1">
        {notifications.map((notification, index) => (
          <li key={index}>
            <a
              href={notification.link}
              className="block px-2 py-1 transition rounded-md hover:bg-gray-200 "
            >
              {notification.text}
            </a>
          </li>
        ))}
      </ul>
      <div className="flex items-center justify-center p-2 text-blue-500 border-t hover:text-blue-900">
        {/* <a href="/notifications">See All</a> */}
        <a href="#">See All</a>
      </div>
    </div>
  );
}
