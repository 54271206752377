import { useState, useEffect, useReducer } from "react";
import { useParams, useLocation } from "react-router-dom";

import SidenavLayout from "pages/layout/sidenav";
import Title from "components/convention/title";
import ChatGPT from "components/ChatGPT";
import { IconVivek, IconChanakya, IconAyur } from "images/icons";
import { getUserId, getStoredToken } from "utils/authentication";

export default function Page() {
  const { guru_key, conversation_xid } = useParams();

  const [fetchedConversations, setFetchedConversations] = useState(false);
  const [fetchedConversationHistory, setFetchedConversationHistory] =
    useState(false);
  const [conversations, setConversations] = useState({ title: "", links: [] });
  const [currentConversationXID, setCurrentConversationXID] =
    useState<string>("");
  const [chatTitle, setChatTitle] = useState("");
  const [currentConversation, setCurrentConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingCoversations, setLoadingCoversations] = useState(false);

  useEffect(() => {
    const fetchConversations = async () => {
      setLoadingCoversations(true);
      try {
        const response = await fetch(
          "https://divshow-api.bytebeacon.com/api/v1/gurus/" +
            guru_key +
            "/conversations",
          {
            method: "GET",
            headers: { Authorization: "Bearer " + getStoredToken() },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let response_data = await response.json();

        const links = response_data.data.map((conversation: any) => ({
          title: conversation.title,
          url: `/gurus/${guru_key}/${conversation.xid}`,
          xid: conversation.xid,
        }));

        const fetched_conversation = {
          title: "History",
          links: links,
        };
        setConversations(fetched_conversation);
      } catch (err) {
      } finally {
        setLoadingCoversations(false);
      }
    };

    const fetchConversationHistory = async () => {
      try {
        const response = await fetch(
          "https://divshow-api.bytebeacon.com/api/v1/gurus/" +
            guru_key +
            "/conversations/" +
            conversation_xid,
          {
            method: "GET",
            headers: { Authorization: "Bearer " + getStoredToken() },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let response_data = await response.json();

        const messages = response_data.data.map((message: any) => ({
          role: message.data.role,
          content: message.data.content,
        }));

        setMessages(messages);
        setChatTitle("");
      } catch (err) {
      } finally {
      }
    };

    if (currentConversationXID !== conversation_xid) {
      setCurrentConversationXID(conversation_xid || "");
      setFetchedConversationHistory(false);
      setFetchedConversations(false);
    }

    let current_conversation = conversations.links.find(
      (element: any) => element.xid === conversation_xid
    );

    setCurrentConversation(current_conversation || null);
    setChatTitle(
      chatTitle
        ? chatTitle
        : current_conversation
        ? current_conversation["title"]
        : ""
    );

    if (!fetchedConversations) {
      fetchConversations();
      setFetchedConversations(true);
    }

    if (conversation_xid && !fetchedConversationHistory) {
      fetchConversationHistory();
      setFetchedConversationHistory(true);
    }
  }, [
    conversation_xid,
    conversations,
    chatTitle,
    fetchedConversationHistory,
    // fetchedConversations,
  ]);

  const onTitleSave = async (new_title: string) => {
    try {
      const body = {
        // user_xid: getUserId(),
        guru_key: guru_key,
        title: new_title,
      };
      const response = await fetch(
        "https://divshow-api.bytebeacon.com/api/v1/gurus/" +
          guru_key +
          "/conversations/" +
          conversation_xid,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getStoredToken(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
      let response_data = await response.json();

      const messages = response_data.data.map((message: any) => ({
        role: message.data.role,
        content: message.data.content,
      }));

      setMessages(messages);
      setChatTitle(new_title);
    } catch (err) {
    } finally {
    }
  };

  const gurus = new Map([
    [undefined, { name: "Unknown" }],
    ["vivek", { name: "Vivek", icon: IconVivek }],
    ["chanakya", { name: "Chanakya", icon: IconChanakya }],
    ["ayur", { name: "Ayur", icon: IconAyur }],
  ]);

  const guru = gurus.get(guru_key) || { name: "Unknown" };

  return (
    <SidenavLayout sidelinks={conversations} loading={loadingCoversations}>
      <Title type="sidenav-page">
        <div className="flex flex-row align-middle items-center w-full">
          {guru.icon ? <guru.icon /> : ""}
          <span className="ml-2 w-full">
            <span className="text-gray-400">Talking to {guru.name} </span>
            <InlineEdit
              value={chatTitle}
              setValue={setChatTitle}
              onSave={onTitleSave}
            />
          </span>
        </div>
      </Title>
      <div className="p-2 ">
        <ChatGPT
          guru_key={guru_key || ""}
          conversation_xid={conversation_xid || null}
          history_messages={messages}
        />
      </div>
    </SidenavLayout>
  );
}

interface InlineEditProps {
  value: string;
  setValue: (x: any) => void;
  onSave: (x: string) => void;
}

const InlineEdit = (props: InlineEditProps) => {
  const [editingValue, setEditingValue] = useState("");
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setEditingValue(props.value);
  }, [props]);

  const onChange = (event: any) => {
    setEditingValue(event.target.value);
  };

  const onKeyDown = (event: any) => {
    setChanged(true);
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event: any) => {
    if (event.target.value.trim() === "") {
      setEditingValue(props.value);
    } else {
      props.setValue(event.target.value);
    }
  };

  const onSave = () => {
    setChanged(false);
    props.onSave(editingValue);
  };

  const onClick = () => {
    setChanged(true);
  };

  return (
    <div className="w-full flex flex-row justify-between">
      <input
        className={
          "focus:outline-none focus:border-teal-500 w-full " +
          (editingValue === "" || changed ? " border-2  rounded   " : "")
          // (changed ? " border-2  rounded   " : "")
        }
        type="text"
        aria-label="Field name"
        placeholder="Starting new chat ... "
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={onClick}
      />
      {changed ? (
        <button
          className="bg-green-200 text-green-700 m-2 p-1  rounded-full text-sm h-5 w-5 hover:bg-green-500 hover:text-white"
          onClick={onSave}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
