// https://react-svgr.com/playground/?expandProps=start&typescript=true

import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    fill="currentColor"
    height="800px"
    width="800px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M488.727,372.364h-7.758v-69.818c0-12.853-10.42-23.273-23.273-23.273h-7.758v-46.545h-23.273h-23.273H256H108.606H85.333
			H62.061v46.545h-7.758c-12.853,0-23.273,10.42-23.273,23.273v69.818h-7.758C10.42,372.364,0,382.782,0,395.636v93.091
			C0,501.582,10.42,512,23.273,512h465.455C501.58,512,512,501.582,512,488.727v-93.091C512,382.782,501.58,372.364,488.727,372.364
			z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M273.015,7.395C268.614,2.68,262.451,0,256,0c-6.451,0-12.614,2.68-17.015,7.395
			c-6.993,7.494-68.318,74.909-68.318,133.793c0,17.256,2.712,32.292,8.094,44.994h26.323H256h50.918h26.321
			c5.382-12.702,8.094-27.738,8.094-44.994C341.333,82.303,280.008,14.888,273.015,7.395z"
        />
      </g>
    </g>
  </svg>
);
export default SvgComponent;
