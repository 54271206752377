import { Link } from "react-router-dom";

export default function Component() {
  const gurus = [
    {
      name: "Vivek",
      initials: "VK",
      url_key: "vivek",
      stats: {
        topics: "4",
        sessions: "12",
        credits: { last_month: 50, total: 50 },
      },
      color_key: "purple",
    },
    {
      name: "Chanakya",
      initials: "CK",
      url_key: "chanakya",
      stats: {
        topics: "2",
        sessions: "24",
        credits: { last_month: 150, total: 600 },
      },
      color_key: "lime",
    },
  ];
  return (
    <>
      {gurus.map((guru, index) => (
        <div
          key={index}
          className="flex flex-col p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg"
        >
          <span
            className={
              "pb-2 text-center text-lg text-" + guru.color_key + "-600"
            }
          >
            {guru.name}
          </span>
          <div className="flex flex-row">
            <div className=" p-4 bg-gray-200 rounded-md text-2xl">
              {guru.initials}
            </div>
            <div className="p-2 flex flex-col">
              <span className="text-gray-400 text-sm ">
                {guru.stats.topics} topics
              </span>
              <span className="text-gray-400 text-sm ">
                {guru.stats.sessions} sessions
              </span>
            </div>
          </div>
          <div className="py-2 text-gray-400  ">
            <div className="text-gray-300 py-1">Credits used</div>
            <div className="text-xs pb-1">
              <span className="inline-block px-2  text-blue-500 bg-blue-50  rounded mr-2 ">
                {guru.stats.credits.last_month}
              </span>
              <span>past 30 days </span>
            </div>
            <div className="text-xs pb-1">
              <span className="inline-block px-2 text-blue-500 bg-blue-50  rounded mr-2 ">
                {guru.stats.credits.total}
              </span>
              <span>Total</span>
            </div>
          </div>
          <Link
            className={
              " text-center rounded my-2 p-2 " +
              " text-" +
              guru.color_key +
              "-600 bg-" +
              guru.color_key +
              "-100 "
            }
            to={"/gurus/" + guru.url_key}
          >
            View chats
          </Link>
        </div>
      ))}
    </>
  );
}
