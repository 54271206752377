import { Link, useNavigate, redirect } from "react-router-dom";
import { deleteToken } from "utils/authentication";
import { useAuth } from "./hooks/useAuth";
import Logo from "images/logo";
import {
  IconDashboard,
  IconGurus,
  IconSettings,
  IconLogout,
} from "images/icons";
import LoadingSVG from "images/loading";

export default function Component({
  isOpen,
  setIsOpen,
  sections,
  loading,
}: {
  isOpen: boolean;
  setIsOpen: any;
  sections: any;
  loading: boolean;
}) {
  // {/* <!-- Sidebar backdrop --> */}
  // {/* <div className="fixed inset-0 z-10 bg-black bg-opacity-20 lg:hidden"></div> */}

  const navigations = {
    title: null,
    links: [
      { title: "Dashboard", url: "/dashboard", icon: IconDashboard },
      { title: "Gurus", url: "/gurus", icon: IconGurus },
      { title: "Settings", url: "/settings", icon: IconSettings },
    ],
  };

  const sidelinks = [navigations];
  if (sections) {
    sidelinks.push(sections);
  }

  const { logout } = useAuth();
  return (
    <aside
      className={
        "fixed bg-gray-50 inset-y-0 z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform border-r shadow-lg lg:z-auto lg:static lg:shadow-none " +
        (isOpen ? "" : " -translate-x-full lg:translate-x-0 lg:w-20 ")
      }
    >
      {/* <!-- sidebar header --> */}
      <div
        className={
          "flex items-center justify-between flex-shrink-0 p-2 " +
          (isOpen ? "" : " lg:justify-center ")
        }
      >
        <span className="p-2 text-xl font-semibold leading-8 whitespace-nowrap">
          {isOpen ? (
            <span className="text-gray-600">Takshila</span>
          ) : (
            <span>
              <Logo className="h-4 w-4 text-gray-600" />
            </span>
          )}
        </span>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 rounded-md lg:hidden"
        >
          <svg
            className="w-6 h-6 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      {/* <!-- Sidebar links --> */}
      <nav className="flex-1 overflow-hidden hover:overflow-y-auto">
        <ul className="p-2 overflow-hidden">
          {sidelinks.map((section, idx) => (
            <ul key={idx} className="border-b ">
              {section.title ? (
                <li
                  className={
                    " px-2 mt-4 flex  text-slate-400 " +
                    (isOpen ? " text-sm " : " text-xs ")
                  }
                >
                  {truncateString(section.title, isOpen ? 25 : 10)}
                </li>
              ) : (
                ""
              )}
              {section.links
                ? section.links.map((item, idx) => (
                    <li
                      key={idx}
                      className={" text-gray-500 rounded hover:bg-gray-200 "}
                    >
                      <Link
                        to={item.url}
                        className={
                          "flex items-center px-1 py-2 space-x-2 " +
                          (isOpen ? "" : " justify-center ")
                        }
                      >
                        {item.icon ? <item.icon /> : ""}
                        <span
                          className={
                            isOpen
                              ? ""
                              : !item.icon
                              ? " text-xs text-center "
                              : " lg:hidden "
                          }
                        >
                          {truncateString(item.title, isOpen ? 35 : 10)}
                        </span>
                      </Link>
                    </li>
                  ))
                : ""}
            </ul>
          ))}
          {loading ? (
            <div className="flex justify-center py-2 ">
              <LoadingSVG className="w-8 h-8 text-gray-300" />
            </div>
          ) : (
            ""
          )}

          {/* // <!-- Sidebar Links... --> */}
        </ul>
      </nav>

      {/* // <!-- Sidebar footer --> */}
      <div className="flex-shrink-0 p-2 max-h-14">
        <button
          className="flex items-center justify-center w-full px-4 py-2 space-x-1 font-sm tracking-wider border-t rounded-md focus:outline-none focus:ring"
          // onClick={(e) => logout(e)}
          onClick={logout}
        >
          <IconLogout />
          <span className={isOpen ? "" : " lg:hidden "}>Logout</span>
        </button>
      </div>
    </aside>
  );
}

function truncateString(str: string, num: number) {
  return str.length > num ? str.substring(0, num) + "..." : str;
}
