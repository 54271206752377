import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useOutlet,
} from "react-router-dom";

import LoginPage from "pages/public/login";
import DashboardPage from "pages/protected/dashboard";
import GurusPage from "pages/protected/gurus";
import GuruChatPage from "pages/protected/guru_chat";
import SettingsPage from "pages/protected/settings";
import NoMatchPage from "pages/generic/no_match";
// import ChatCompletion from "utils/chat-completion";

import "./App.css";
import { useAuth, AuthProvider } from "components/hooks/useAuth";
import { GoogleOAuthProvider } from "@react-oauth/google";

const HomeLayout = () => {
  const { token } = useAuth();
  const outlet = useOutlet();

  if (token) {
    return <Navigate to="/" replace />;
  }

  return <div>{outlet}</div>;
};

const ProtectedLayout = () => {
  const { token } = useAuth();
  const outlet = useOutlet();

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <div>{outlet}</div>;
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_APP_ID || ""}
        >
          <Routes>
            <Route element={<HomeLayout />}>
              {/* <Route path="/" element={<HomePage />} /> */}
              <Route path="/login" element={<LoginPage />} />
            </Route>

            <Route path="/" element={<ProtectedLayout />}>
              <Route path="" element={<DashboardPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="gurus/:guru_key" element={<GuruChatPage />} />
              <Route path="gurus" element={<GurusPage />} />
              <Route
                path="gurus/:guru_key/:conversation_xid"
                element={<GuruChatPage />}
              />
              <Route path="settings" element={<SettingsPage />} />
              {/* <Route path="api/chat-completion" handle={ChatCompletion} /> */}
            </Route>

            {/* This has to be at the end of the list */}
            <Route path="*" element={<NoMatchPage />} />
          </Routes>
        </GoogleOAuthProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
