import { useEffect, useReducer, useRef, useState } from "react";
// import { useAuth, AuthProvider } from "components/hooks/useAuth";
import { getStoredToken, getUserId } from "utils/authentication";

import ClipboardJS from "clipboard";
import { throttle } from "lodash-es";

import { ChatGPTProps, ChatMessage, ChatRole } from "./interface";

const scrollDown = throttle(
  () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  },
  300,
  {
    leading: true,
    trailing: false,
  }
);

const requestMessage = async (
  guru: string,
  url: string,
  messages: ChatMessage[],
  controller: AbortController | null
) => {
  const question = "";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      guru,
      question,
      messages,
    }),
    signal: controller?.signal,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = response.body;

  if (!data) {
    throw new Error("No data");
  }

  return data.getReader();
};

export const useChatGPT = (props: ChatGPTProps) => {
  const { guru_key, conversation_xid, history_messages } = props;

  const [conversationXID, setConversationXID] = useState<string>("");
  const [messages, setMessages] = useState<ChatMessage[]>(history_messages);
  const [disabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const controller = useRef<AbortController | null>(null);
  const currentMessage = useRef<string>("");

  useEffect(() => {
    // console.log("running effect", history_messages);
    setConversationXID(conversation_xid || "");
    setMessages(props.history_messages);
  }, [props.conversation_xid, props.history_messages]);

  const archiveCurrentMessage = () => {
    const content = currentMessage.current;
    currentMessage.current = "";
    setLoading(false);
    if (content) {
      setMessages((messages) => {
        return [...messages, { content, role: ChatRole.Assistant }];
      });
      scrollDown();
    }
  };

  const fetchMessage = async (messages: ChatMessage[]) => {
    try {
      currentMessage.current = "";
      controller.current = new AbortController();
      setLoading(true);

      // const reader = await requestMessage(
      //   guru,
      //   fetchPath,
      //   messages,
      //   controller.current
      // );
      // const decoder = new TextDecoder("utf-8");
      // let done = false;
      // while (!done) {
      //   const { value, done: readerDone } = await reader.read();
      //   if (value) {
      //     const char = decoder.decode(value);
      //     if (char === "\n" && currentMessage.current.endsWith("\n")) {
      //       continue;
      //     }
      //     if (char) {
      //       currentMessage.current += char;
      //       forceUpdate();
      //     }
      //     scrollDown();
      //   }
      //   done = readerDone;
      // }

      const body = {
        // user_xid: getUserId(),
        guru_key: guru_key,
        conversation_xid: conversation_xid || conversationXID,
        chat_history: [...messages],
        query: messages[messages.length - 1].content,
      };
      const response = await fetch(
        "https://divshow-api.bytebeacon.com/api/v1/gurus/conversations/query",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + getStoredToken(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      currentMessage.current += data.data.content;
      setConversationXID(data.data.conversation_xid);
      scrollDown();

      if (!conversation_xid) {
        window.history.pushState(
          {},
          "",
          `/gurus/${guru_key}/${data.data.conversation_xid}`
        );
      }

      archiveCurrentMessage();
    } catch (e) {
      console.error(e);
      setLoading(false);
      return;
    }
  };

  const onStop = () => {
    if (controller.current) {
      controller.current.abort();
      archiveCurrentMessage();
    }
  };

  // const onLoad = () => {
  //   const loadedMessages: ChatMessage[] = [];
  //   setMessages(loadedMessages);
  // };

  const onSend = (message: ChatMessage) => {
    const newMessages = [...messages, message];
    setMessages(newMessages);
    fetchMessage(newMessages);
  };

  const onClear = () => {
    setMessages([]);
  };

  useEffect(() => {
    new ClipboardJS(".chat-wrapper .copy-btn");
  }, []);

  return {
    loading,
    disabled,
    messages,
    currentMessage,
    onSend,
    onClear,
    onStop,
  };
};
