import { useState } from "react";

import SidenavLayout from "pages/layout/sidenav";
import Title from "components/convention/title";

export default function Page() {
  return (
    <SidenavLayout>
      <Title type="sidenav-page">Settings</Title>
      <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-4">
        coming soon ...
      </div>
    </SidenavLayout>
  );
}
