import { useState } from "react";

import SidenavLayout from "pages/layout/sidenav";
import Title from "components/convention/title";

import { IconVivek, IconChanakya, IconAyur } from "images/icons";

export default function Page() {
  return (
    <SidenavLayout>
      <Title type="sidenav-page">Gurus</Title>
      <div className="grid grid-cols-1 gap-1 mt-6 sm:grid-cols-2 lg:grid-cols-3">
        <GuruList />
      </div>
    </SidenavLayout>
  );
}

function GuruList() {
  let gurus: any[] = [
    {
      name: "Vivek",
      initials: "VK",
      url_key: "vivek",
      css: { text: " text-purple-600 ", bg: " bg-purple-100 " },
      desc: "Plan your path to higher education",
      icon: IconVivek,
    },
    {
      name: "Chanakya",
      initials: "CK",
      url_key: "chanakya",
      css: { text: " text-lime-600 ", bg: " bg-lime-100 " },
      desc: "Achieve your financial goals through personalized strategies",
      icon: IconChanakya,
    },
    {
      name: "Ayur",
      initials: "AY",
      url_key: "ayur",
      css: { text: " text-red-600 ", bg: " bg-red-100 " },
      desc: "Achieving holistic well-being through natural healing practices",
      icon: IconAyur,
    },
  ];

  return (
    <>
      {gurus.map((guru, index) => (
        <div key={index} className="flex flex-col">
          <a
            href={"/gurus/" + guru.url_key}
            className={" m-2 p-6 bg-white border rounded-lg "}
          >
            <div
              className={
                "w-full flex justify-center items-center h-16 rounded-full " +
                guru.css.bg +
                guru.css.text
              }
            >
              <guru.icon />
            </div>
            <h5 className="w-full mt-4 mb-2 text-center font-bold ">
              {guru.name}
            </h5>

            <div className="flex-1">
              <p className="mb-6  text-gray-600 text-sm">{guru.desc}</p>

              <span className={"flex items-center text-sm " + guru.css.text}>
                Start chat
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
            </div>
          </a>
        </div>
      ))}
    </>
  );
}
