export default function Component() {
  const members = [
    {
      name: "Prashant Khanduri",
      avatar: "https://avatars.githubusercontent.com/u/513919?v=4",
      email: "prashant.khanduri@example.com",
      relationship: "Self",
      status: "active",
      role: "admin",
    },
    {
      name: "Puneet Khanduri",
      avatar: "https://avatars.githubusercontent.com/u/1631032?v=4",
      email: "puneet.khanduri@example.com",
      relationship: "Family",
      status: "active",
      role: "member",
    },
    {
      name: "Piyush Gupta",
      avatar:
        "https://ca.slack-edge.com/T06169Q7DCM-U061MSN7C1F-867948dbccd4-72",
      email: "piyush.gupta@example.com",
      relationship: "Council",
      status: "active",
      role: "member",
    },
    {
      name: "Prashanth Ranganathan",
      avatar:
        "https://ca.slack-edge.com/T06169Q7DCM-U061BLLUD1S-95d36e4f6e25-72",
      email: "prashanth.ranganathan@example.com",
      relationship: "Observer",
      status: "active",
      role: "viewer",
    },
  ];
  return (
    <>
      {/* <!-- Start Content --> */}

      {/* <!-- Table see (https://tailwindui.com/components/application-ui/lists/tables) --> */}
      <div className="grid gap-5 mt-6 grid-cols-1">
        {/* <div className="border rounded-lg shadow-sm"> */}
        <div className="border-t">
          <div className="flex items-center justify-between pt-4 pb-2">
            <h5 className="font-semibold">Team: This is a demo table</h5>
          </div>
          <div className="flex flex-col mb-6 ">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 rounded-md shadow-md">
                  <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Relationship
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Role
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {members.map((member, idx) => (
                        <tr
                          key={idx}
                          className="transition-all hover:bg-gray-100 hover:shadow-lg"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 w-10 h-10">
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={member.avatar}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {member.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {member.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {member.relationship}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100  rounded-full">
                              Active
                            </span>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {member.role}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              href="#"
                              className="text-secondary-600 hover:text-secondary-900"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
