import Dashboard from "components/dashboard_content";

import SidenavLayout from "pages/layout/sidenav";

import UserCard from "components/user_card";
import Title from "components/convention/title";
import { getUserId } from "utils/authentication";

export default function Page() {
  return (
    <SidenavLayout>
      {/* <Title type="sidenav-page">Dashboard: {getUserId()}</Title> */}
      <Title type="sidenav-page">Dashboard </Title>

      <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-4">
        <UserCard />
      </div>

      <Dashboard />
    </SidenavLayout>
  );
}
