import { useState } from "react";

import PanelProfile from "components/panel_profile";
import PanelOptions from "components/panel_options";

export default function Component({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setIsSidebarOpen: any;
}) {
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

  return (
    <>
      {/* <!-- Navbar --> */}
      <header className="flex-shrink-0 border-b">
        <div className="flex items-center justify-between p-2">
          {/* <!-- Navbar left --> */}
          <div className="flex items-center space-x-3">
            <span className="p-2 text-xl font-semibold tracking-wider lg:hidden">
              Takshila
            </span>
            {/* <!-- Toggle sidebar button --> */}
            <button
              // @click="toggleSidbarMenu()"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-md focus:outline-none focus:ring"
            >
              <svg
                className={
                  "w-4 h-4 text-gray-600 " +
                  (isSidebarOpen
                    ? " transform transition-transform -rotate-180  "
                    : " ")
                }
                // :className="{'transform transition-transform -rotate-180': isSidebarOpen}"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>

          {/* <!-- Navbar right --> */}
          <div className="relative flex items-center space-x-3">
            <div className="items-center hidden space-x-3 md:flex">
              {/* <!-- Notification Button --> */}
              <div className="relative">
                <div className="absolute right-0 p-1 bg-red-400 rounded-full animate-ping"></div>
                <div className="absolute right-0 p-1 bg-red-400 border rounded-full"></div>
                <button
                  onClick={() => setIsNotificationPanelOpen(true)}
                  className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none focus:ring"
                >
                  <svg
                    className="w-6 h-6 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>

                {/* <!-- Dropdown card --> */}
                <PanelOptions
                  isOpen={isNotificationPanelOpen}
                  setIsOpen={setIsNotificationPanelOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
