import React, { useEffect, useState } from "react";

import MessageItem from "./MessageItem";
import SendBar from "./SendBar";
import { useChatGPT } from "./useChatGPT";
import { ChatGPTProps, ChatMessage, ChatRole } from "./interface";

import "./index.scss";

function reverseArray(original_array: any[]) {
  var ret = new Array();
  for (var i = original_array.length - 1; i >= 0; i--) {
    ret.push(original_array[i]);
  }
  return ret;
}

const ChatGPT = (props: ChatGPTProps) => {
  const {
    loading,
    disabled,
    messages,
    currentMessage,
    onSend,
    onClear,
    onStop,
  } = useChatGPT(props);

  // const [messages, setMessages] = useState<ChatMessage[]>([]);
  // const [disabled] = useState<boolean>(false);
  // const [conversationXID, setConversationXID] = useState<string>("");
  // const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="chat-wrapper">
      <SendBar
        loading={loading}
        disabled={disabled}
        onSend={onSend}
        onClear={onClear}
        onStop={onStop}
      />
      {currentMessage.current && (
        <MessageItem
          message={{
            content: currentMessage.current,
            role: ChatRole.Assistant,
          }}
        />
      )}
      {reverseArray(messages).map((message, index) => (
        <MessageItem key={index} message={message} />
      ))}
    </div>
  );
};

export default ChatGPT;
